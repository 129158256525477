import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react';

export const DecentLogo: ComponentWithAs<'svg', IconProps> = createIcon({
  displayName: 'DecentLogo',
  viewBox: '0 0 33 40',
  path: (
    <svg
      width="33"
      height="40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2711 0H17.7677C17.0406 0 16.4516 0.599301 16.4516 1.33922V6.61239C16.4516 7.35231 15.8626 7.95161 15.1355 7.95161H8.63216C8.10818 7.95161 7.6064 8.16338 7.23624 8.54003L0.578274 15.3148C0.208113 15.6915 0 16.2029 0 16.7352V30.4672C0 31.2072 0.588968 31.8065 1.31613 31.8065H6.90968C7.63684 31.8065 8.22581 31.2072 8.22581 30.4672V17.2424C8.22581 16.5025 8.81477 15.9032 9.54194 15.9032H15.1355C15.8626 15.9032 16.4516 15.3039 16.4516 14.564V9.62564C16.4516 8.88572 17.0406 8.28642 17.7677 8.28642H23.3613C24.0884 8.28642 24.6774 8.88572 24.6774 9.62564V30.4672C24.6774 31.2072 24.0884 31.8065 23.3613 31.8065H9.54194C8.81477 31.8065 8.22581 32.4058 8.22581 33.1457V38.4188C8.22581 39.1588 8.81477 39.7581 9.54194 39.7581H24.2275C24.749 39.7581 25.2491 39.548 25.6193 39.1738L32.3208 32.3957C32.6935 32.0191 32.9032 31.506 32.9032 30.9711V8.50655C32.9032 8.15166 32.7642 7.811 32.5174 7.55989L25.667 0.588419C25.2968 0.211764 24.7942 0 24.2711 0Z"
        fill="#DCC8F0"
      />
    </svg>
  ),
});

import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react';

export const TokenPlaceholder: ComponentWithAs<'svg', IconProps> = createIcon({
  displayName: 'TokenPlaceholder',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M15.508 4.383c-.945-1.617-2.235-2.508-3.633-2.508h-3.75c-1.398 0-2.688.89-3.633 2.508C3.547 6 3.125 7.89 3.125 10c0 2.11.484 4.11 1.367 5.617.883 1.508 2.235 2.508 3.633 2.508h3.75c1.398 0 2.688-.89 3.633-2.508C16.453 14 16.875 12.11 16.875 10c0-2.11-.484-4.11-1.367-5.617Zm.101 4.992h-2.5c-.062-1.344-.32-2.633-.757-3.75h2.39c.477 1.047.797 2.344.867 3.75Zm-1.609-5h-2.242a6.95 6.95 0 0 0-.938-1.25h1.055c.781 0 1.516.469 2.125 1.25ZM4.375 10c0-3.726 1.719-6.875 3.75-6.875 2.031 0 3.75 3.149 3.75 6.875 0 3.727-1.719 6.875-3.75 6.875-2.031 0-3.75-3.148-3.75-6.875Zm7.5 6.875H10.82c.359-.38.673-.8.938-1.25H14c-.61.781-1.344 1.25-2.125 1.25Zm2.867-2.5h-2.39c.437-1.117.695-2.406.757-3.75h2.5c-.07 1.406-.39 2.703-.867 3.75Z"
      fill="currentColor"
    />
  ),
});
